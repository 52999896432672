import React from 'react'

const Experience = () => {
  return (
<div className='main-timeline'>
  <div className="container">
  <div>
      <h2 id="experience" className='heading my-4'> <i className="fas fa-briefcase" /> Experience</h2>
    </div>
    <ul className="timeline">
      <li>
        <div className="timeline-badge" />
        <div className="timeline-panel">
          <div className="timeline-heading">
            <h4 className="timeline-title">ONPASSIVE Technologies Pvt. Ltd, <i className="text-muted fon">Hyderabad</i></h4>
            
            <h5>Senior Software Engineer UI</h5>
        
          </div>
          <div className="timeline-body">
          <p className="text-muted">
              <i class="fas fa-calendar-alt"></i> January 2021 – Present
           
            </p>
          </div>
        </div>
      </li>
      <li className="timeline-inverted">
        <div className="timeline-badge" />
        <div className="timeline-panel">
          <div className="timeline-heading">
            <h4 className="timeline-title">OMICS International Pvt Ltd,  <i className="text-muted">Hyderabad</i></h4>
            <h5>UI Developer</h5>
        
          </div>
          <div className="timeline-body">
          <p className="text-muted">
              <i class="fas fa-calendar-alt"></i> March 2020- February-2021
           
            </p>
          </div>
        </div>
      </li>
      <li>
        <div className="timeline-badge" />
        <div className="timeline-panel">
          <div className="timeline-heading">
            <h4 className="timeline-title">Nova Agri Tech Ltd, <i className="text-muted fon">Hyderabad</i></h4>
            
            <h5>Web Designer</h5>
        
          </div>
          <div className="timeline-body">
          <p className="text-muted">
              <i class="fas fa-calendar-alt"></i>  June 2017 - February 2020
           
            </p>
          </div>
        </div>
      </li>
      <li className="timeline-inverted">
        <div className="timeline-badge" />
        <div className="timeline-panel">
          <div className="timeline-heading">
            <h4 className="timeline-title">Plexasys Solutions Pvt Ltd, <i className="text-muted">Hyderabad</i></h4>
            <h5>Web Designer</h5>
                  </div>
          <div className="timeline-body">
          <p className="text-muted">
              <i class="fas fa-calendar-alt"></i>    October 2016 - May 2017
           
            </p>
          </div>
        </div>
      </li>
 
 
    </ul>
  </div>
</div>

  )
}

export default Experience
