import React, { Fragment } from 'react'

const Education = () => {
  return (
<Fragment>
      {/* education section starts */}
  <section className="education" id="education">
    <h1 className="heading">
      <i className="fas fa-graduation-cap" /> My <span>Education</span>
    </h1>
    <p className="qoute">
      Education is not the learning of facts, but the training of the mind to
      think.
    </p>
    <div className="box-container">
      <div className="box">
        <div className="image">
          <img
            draggable="false"
            src={require("../../../assets//images/lords.png")}   
            alt=""
          />
        </div>
        <div className="content">
          <h3>Master of Business Administration</h3>
          <p>Lords Institute of Engineering & Technology</p>
          <h4>2014-2016 | Completed</h4>
        </div>
      </div>
      <div className="box">
        <div className="image">
          <img
            draggable="false"
            src={require("../../../assets//images/avcollage.jpg")}            
            alt=""
          />
        </div>
        <div className="content">
          <h3>Bachelor of Commerce</h3>
          <p>AV College of Arts, Science and Commerce</p>
          <h4>2011-2014 | Completed</h4>
        </div>
      </div>
    </div>
  </section>
  {/* education section ends */}
</Fragment>
  )
}

export default Education
