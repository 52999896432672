import React, { Fragment } from 'react'

const Footer = () => {
  return (
<Fragment>
      {/* footer section starts */}
  <section className="footer">
    <div className="box-container">
      <div className="box">
        <h3>Murali's Portfolio</h3>
        <p>
          Thank you for visiting my personal portfolio website. Connect with me
          over socials. <br /> <br /> Keep Rising 🚀. Connect with me over live
          chat!
        </p>
      </div>
      <div className="box">
        <h3>quick links</h3>
        <a href="#home">
          <i className="fas fa-chevron-circle-right" /> home
        </a>
        <a href="#about">
          <i className="fas fa-chevron-circle-right" /> about
        </a>
        <a href="#skills">
          <i className="fas fa-chevron-circle-right" /> skills
        </a>
        <a href="#education">
          <i className="fas fa-chevron-circle-right" /> education
        </a>
        <a href="#work">
          <i className="fas fa-chevron-circle-right" /> work
        </a>
        <a href="#experience">
          <i className="fas fa-chevron-circle-right" /> experience
        </a>
      </div>
      <div className="box">
        <h3>contact info</h3>
        <p>
          <i className="fas fa-phone" />
          +91 9059524222
        </p>
        <p className='text-truncate'>
          <i className="fas fa-envelope" />
          veerla.muralikrishna.s99@gmail.com
        </p>
        <p>
          <i className="fas fa-map-marked-alt" />
          Hyderabad, India-500008
        </p>
        <div className="share">
          <a
            href="#"
            className="fab fa-linkedin"
            aria-label="LinkedIn"
            target="_blank"
          />
          <a
            href="#"
            className="fab fa-github"
            aria-label="GitHub"
            target="_blank"
          />
          <a
            href="mailto:veerla.muralikrishna.s99@gmail.com"
            className="fas fa-envelope"
            aria-label="Mail"
            target="_blank"
          />
          <a
            href=""
            className="fab fa-twitter"
            aria-label="Twitter"
            target="_blank"
          />
          <a
            href=""
            className="fab fa-telegram-plane"
            aria-label="Telegram"
            target="_blank"
          />
        </div>
      </div>
    </div>
    <h1 className="credit">
      Designed with <i className="fa fa-heart pulse" /> by
      <a href="#"> muralikrishna</a>
    </h1>
  </section>
  {/* footer section ends */}
</Fragment>
  )
}

export default Footer
