import logo from './logo.svg';
import './App.scss';
import AppRoutes from './AppRouts';

function App() {
  return (
<AppRoutes/>
  );
}

export default App;
