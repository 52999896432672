import React, { Fragment } from 'react'
import skills from './skills.json'

const Skills = () => {
    console.log(skills,"skillsskillsskills")
  return (
<Fragment>
  {/* skills section starts */}
  <section className="skills" id="skills">
    <h2 className="heading">
      <i className="fas fa-laptop-code" /> Skills &amp; <span>Abilities</span>
    </h2>
    <div className="container">
      <div className="row" id="skillsContainer">
        {skills?.map((skill)=>
        <div class="bar">
        <div class="info">
          <img src={skill?.icon}/>
          <span>{skill?.name}</span>
        </div>
      </div>
        )}
        {/* <div class="bar">
        <div class="info">
          <img src="https://img.icons8.com/color/48/000000/adobe-xd.png"/>
          <span>AdobeXD</span>
        </div>
      </div> */}
      </div>
    </div>
  </section>
  {/* skills section ends */}
</Fragment>
  )
}

export default Skills
