import React, { Fragment } from 'react';
import './Portfolio.scss'; // Assuming you have a corresponding CSS file
import Particles from 'react-particles-js';
import Portfoliojs from './Portfoliojs'
import Animate from 'react-smooth';
import { Col, Container, Row } from 'react-bootstrap';
import { TypeAnimation } from 'react-type-animation';
import Experience from './components/Experience';
import Education from './components/Education';
import Skills from './components/Skills';
import Work from './components/Work';
import Footer from './components/Footer';
import Contact from './components/Contact';

const Portfolio = () => {
  return (
<Fragment>
  <Header/>
  <Banner/>
  <About/>
  <Skills/>  
  <Education/>
  {/* <Work/> */}
  <Experience/>
  {/* <Contact/> */}
   <Footer/>
</Fragment>

  );
}
const Header=()=>{
return(
  <header>
  <a href="/" className="logo">
    <figure className='logo'>
 <img src={require("../../assets/images/vmklogo.png")} alt ="logo" className='img-fluid'/>
 </figure>
  </a>
  <div id="menu" className="fas fa-bars" />
  <nav className="navbar">
    <ul>
      <li>
        <a className="active" href="#home">
          Home
        </a>
      </li>
      <li>
        <a href="#about">About</a>
      </li>
      <li>
        <a href="#skills">Skills</a>
      </li>
      <li>
        <a href="#education">Education</a>
      </li>
      <li>
        <a href="#work">Work</a>
      </li>
      <li>
        <a href="#experience">Experience</a>
      </li>
  
    </ul>
  </nav>
</header>
)
}
const Banner=()=>{
  return(
    <Fragment>
        {/* hero section starts */}
  <section className="home position-relative" id="home">
    <HomeBanner />
    <div className="content">
      <h2>
        Hi There,
        <br /> I'm Murali<span><TypeAnimation
  sequence={[
    1000,
    ' Krishna',
  ]}
  speed={20}
  style={{ fontSize: '1em' }}
  repeat={false}
/></span>
      </h2>
      <p>
        i am into <span className="typing-text">
        <TypeAnimation
  sequence={[
    'Se',
    2000,
    'Senior Software Engineer UI',
  ]}
  speed={10}
  style={{ fontSize: '1em' }}
  repeat={Infinity}
/>


        </span>
      </p>
      <a href="#about" className="btn">
        <span>About Me</span>
        <i className="fas fa-arrow-circle-down" />
      </a>
      <div className="socials">
        <ul className="social-icons">
          <li>
            <a
              className="linkedin"
              aria-label="LinkedIn"
              href="https://www.linkedin.com/in/muralikrishna-veerla-545038ab/"
              target="_blank"
            >
              <i className="fab fa-linkedin" />
            </a>
          </li>
          <li>
            <a
              className="github"
              aria-label="GitHub"
              href="https://github.com/vmk4692"
              target="_blank"
            >
              <i className="fab fa-github" />
            </a>
          </li>
          <li>
            <a
              className="twitter"
              aria-label="Twitter"
              href=""
              target="_blank"
            >
              <i className="fab fa-twitter" />
            </a>
          </li>

        </ul>
      </div>
    </div>
    <div className="image">
      <img
        draggable="false"
        className="tilt"
        src={require("../../assets/images/profilepic.png")}
        alt=""
      />
    </div>
  </section>
  {/* hero section ends */}
    </Fragment>
  )
}
const HomeBanner=()=>{
  return(
    <Animate to="1" from="0" attributeName="opacity">
    <Particles
    params={Portfoliojs}
    className="particle"
    />
</Animate>
  )
}
const About=()=>{
  return(
    <Fragment>
        {/* about section starts */}
  <section className="about" id="about">
    <h2 className="heading">
      <i className="fas fa-user-alt" /> About <span>Me</span>
    </h2>
    <div className="row">
      <div className="image">
        <img
          draggable="false"
          className="tilt"
          src={require("../../assets/images/about.jpg")}
          alt=""
        />
      </div>
      <div className="content">
        <h3>I'm Murali Krishna</h3>
        <span className="tag">Frontend Developer</span>
        <p>
          I am a Frontend developer based in Hyderabad, India. I am very passionate about
          improving my coding skills &amp; developing applications &amp;
          websites. I build WebApps and Websites using React. Working for
          myself to improve my skills. 
        </p>
        <div className="box-container">
          {/* <div class="box">
        <p><span> age: </span> 20</p>
        <p><span> phone : </span> +91 XXX-XXX-XXXX</p>
      </div> */}
          <div className="box">
            <p>
              <span> email : </span> veerla.muralikrishna.s99@gmail.com
            </p>
            <p>
              <span> place : </span> Hyderabad, India - 9059524222
            </p>
          </div>
        </div>
        <div className="resumebtn">
          <a
            href="https://muralikrishnaveerla.in/pdf/Muralikrishna_Veerla_Resume_March_2024.pdf"
            target="_blank"
            className="btn"
          >
            <span>Resume</span>
            <i className="fas fa-chevron-right" />
          </a>
        </div>
      </div>
    </div>
  </section>
  {/* about section ends */}
    </Fragment>
  )
}

export default Portfolio;
