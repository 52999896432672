import * as React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import Portfolio from "./modules/portfolio/Particle";

const AppRoutes = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Portfolio/>
    },
    {
      path: "/about", // Corrected path
      element: <div>About</div>,
    },
  ]);

  createRoot(document.getElementById("root")).render(
    <RouterProvider router={router} />
  );

  return null; 
};

export default AppRoutes;
